<template>
	<section class="container">
		<Card background="block">
			<template #header>
				<HeaderCurso 
					:title-curso="curso.title"
					:step="2"
				/>
			</template>
			<div class="ebradi-card-body">
				<HeaderInfo
					:image="header_info.image"
					:title="header_info.title"
					:sub-title="header_info.sub_title"
				/>
				<div class="box-resumo">
					<Card 
						bars
						background="blur"
					>
						<template #header>
							<div class="card-custom-margin">
								<h2>{{ curso.title }}</h2>
								<h5><b>Forma de pagamento:</b> Crédito parcelado em 12x (R$158,25)</h5>
							</div>
						</template>
						<div class="is-flex flex-wrap is-justify-content-space-between card-custom-margin">
							<div>
								<b>Nome completo:</b> Jaime dos Santos Moura Silva<br>
								<b>Telefone:</b> (11) 91234-5678<br>
								<b>Email:</b> email@email.com<br>
								<b>CPF:</b> 123.456.789-00<br>
								<b>Endereço de cobrança:</b> Rua Oratório, 12, Mooca, São Paulo-S<br>
							</div>
							<Price
								:price="19229.99"
								:specialPrice="12219.99"
								:desconto="5"
							/>
						</div>
					</Card>
				</div>
			</div>
		</Card>
	</section>
</template>

<script>
import Card from '@ebradi/componets/Card'
import Price from '@ebradi/componets/Price'
import HeaderCurso from '@ebradi/componets/HeaderCurso'
import HeaderInfo from '@ebradi/componets/HeaderInfo'

export default {
	name: 'ResumoPageEBRADI',
	components: {
		Card,
		Price,
		HeaderCurso,
		HeaderInfo
	},
	data() {
		return {
			header_info: {
				title: 'Pagamento e Contrato',
				sub_title: 'Concluindo esta etapa você terá acesso ao portal',
				image: require('@ebradi/assets/img/image-1.svg')
			},
			curso: {
				title: 'Pós-graduação Online em Direito Societário Aplicado + Execução Penal'
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	padding: 50px 0;
	max-width: 1158px!important;
	padding-bottom: 400px;
}

.ebradi-card-body {
	padding: 42px 82px;

	.btns {
		text-align: center;
		margin-top: 72px;
		margin-bottom: 22px;

		.btn-margin-right {
			margin-right: 32px;
		}
	}
}

.box-resumo {
	margin-top: 88px;
}

.card-custom-margin {
	padding: 32px 44px;

	h2 {
		color: var(--secondary-400);
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
	}


	&:last-child {
		padding-top: 0px;
	}
}
</style>
